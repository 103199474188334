'use client'
import React from 'react'
import { SessionProvider } from 'next-auth/react'

interface ProvidersProps {
  children: React.ReactNode
}

const Providers = ({ children }: ProvidersProps) => (
  <SessionProvider>
    {children}
  </SessionProvider>
)

export default Providers
